<template>
  <div>
    <el-menu :default-active="$route.path" router mode="horizontal" class="el-menu-demo" >
      <el-menu-item index="/content" >
        <i class="el-icon-elem"></i>
        <span>简历填写</span>
      </el-menu-item>
      <el-menu-item index="time" >
        <i class="el-icon-elem"></i>
        <span>面试时间</span>
      </el-menu-item>
      <el-menu-item index="interviewing">
        <i class="el-icon-elem"></i>
        <span>正在面试</span>
      </el-menu-item>
    </el-menu>

    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
}
</script>

<style scoped>
</style>
