import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../views/Layout'
import content from "@/views/Interview/Content";
import Cookies from "js-cookie";

Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name:'Login',
    component: ()=> import ('../views/login/UserLogin.vue'),
  },
  {
    path: '/forget',
    name:'/Forget',
    component: ()=> import ('../views/forget/Forget.vue'),
  },
  {
    path: '/forgetCode',
    name:'/ForgetCode',
    component: ()=> import ('../views/forget/ForgetCode.vue'),
  },
  {
    path: '/forgetCode2',
    name:'/ForgetCode2',
    component: ()=> import ('../views/forget/ForgetCode2.vue'),
  },
  {
    path: '/forgetPassword',
    name:'/ForgetPassword',
    component: ()=> import ('../views/forget/ForgetPassword.vue'),
  },
  {
    path: '/register',
    name:'Register',
    component: ()=> import ('../views/register/Register.vue'),
  },
  {
    path: '/registerCode',
    name:'RegisterCode',
    component: ()=> import ('../views/register/RegisterCode.vue'),
    props: true
  },
  {
    path: '/registerCode2',
    name:'RegisterCode2',
    component: ()=> import ('../views/register/RegisterCode2.vue'),
    props: true
  },
  {
    path: '/registerPassword',
    name:'RegisterPassword',
    component: ()=> import ('../views/register/RegisterPassword.vue'),
    props: true
  },

  {
    path: '/',
    name: 'Layout',
    redirect:'/home',
    component: layout,
    children:[
      {
        path: 'home',
        name:'HomeView',
        component: ()=> import ('../views/home/HomeView.vue'),
      },
      {
        path: 'chatGPT',
        name:'ChatGPT',
        component: ()=> import ('../views/home/ChatGPT.vue'),
      },
      //=======competition======
      {
        path: 'competition',
        name: 'Competition',
        component: ()=> import ('../views/competition/Competition.vue'),
      },
      {
        path: 'competitionMessage',
        name: 'CompetitionMessage',
        component: ()=> import ('../views/competition/CompetitionMessage.vue'),
      },
      //=======news======
      {
        path: 'news',
        name:'News',
        component: ()=> import ('../views/news/NewsPublish.vue'),
      },
      {
        path: 'page1_1',
        name: 'Page1_1',
        component: ()=> import ('../views/home/subpage1/Page1_1.vue'),
      },
      {
        path: 'page1_2',
        name: 'Page1_2',
        component: ()=> import ('../views/home/subpage1/Page1_2.vue'),
      }, {
        path: 'page1_3',
        name: 'Page1_3',
        component: ()=> import ('../views/home/subpage1/Page1_3.vue'),
      }, {
        path: 'page1_4',
        name: 'Page1_4',
        component: ()=> import ('../views/home/subpage1/Page1_4.vue'),
      },

      {
        path: 'funding',
        name: 'Funding',
        component: ()=> import ('../views/home/subpage2/Funding.vue'),
      }, {
        path: 'projects',
        name: 'Projects',
        component: ()=> import ('../views/home/subpage2/Projects.vue'),
      }, {
        path: 'talent',
        name: 'Talent',
        component: ()=> import ('../views/home/subpage2/Talent.vue'),
      },
      //=======Interview======
      {
        path: 'publish',
        name:'Publish',
        component: ()=> import ('../views/Interview/Publish.vue'),
      },
      {
        path: 'content',
        name: 'Content',
        redirect: 'content/message',
        component: () => import ('../views/Interview/Content.vue'),
        children: [
          {
            path: 'message',
            name: 'Message',
            component: () => import ('../views/Interview/content/Message.vue'),
          },
          {
            path: 'time',
            name: 'Time',
            component: () => import ('../views/Interview/content/Time.vue'),
          },
          {
            path: 'interviewing',
            name: 'Interviewing',
            component: () => import ('../views/Interview/content/Interviewing.vue'),
          },
        ]
      }

    ]
  },
  {
    path: '*',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to , form , next) => {
  if (to.path === '/login') next()
  const user = Cookies.get("user")
  if (!user && to.path !== '/login' && to.path !== '/forget'&& to.path !== '/forgetCode'&& to.path !== '/forgetCode2'&&to.path !== '/forgetPassword'&& to.path !== '/register'&& to.path !== '/registerCode'&& to.path !== '/registerCode2'&& to.path !== '/registerPassword') return next('/login') //强制退回登录页面
  next()
})

export default router
