<template>
  <div style="height: 100vh; width: 100vw; overflow: hidden;"> <!-- 整体容器宽度适应视口 -->
    <!--头部区域-->
    <div style="height: 60px; line-height: 60px; background-color: #ede4ff; margin-bottom: 2px; display: flex; position: fixed; width: 100%; top: 0; z-index: 1000;">
      <div style="width: 900px; display: flex; align-items: center;">
        <img src="@/assets/logo1.png" alt="Logo" style="width: 300px; position: relative; top: 5px; left: 10px">
        <img src="@/assets/logo.png" alt="Logo" style="width: 100px; margin-left: 20px;">
        <img src="@/assets/logo2.png" alt="Logo Text" style="width: 200px; margin-left: 5px;">
      </div>
      <div style="flex: 1; text-align: right; padding-right: 60px;">
        <el-dropdown @command="logout">
          <span class="el-dropdown-link">
            {{ user.username }} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!--分割线-->
    <div style="height: 4px; background-color: #8C52FF; margin-top: 60px;"></div> <!-- 分割线位置调整 -->

    <!--侧边栏和主体-->
    <div style="display: flex; height: calc(100vh - 64px); overflow: hidden;"> <!-- 计算高度，确保主体区域可以滚动 -->
      <!--侧边栏-->
      <div style="width: 200px; background-color: white; position: fixed; top: 64px; left: 0; height: calc(100vh - 64px); overflow-y: auto; overflow-x: hidden;"> <!-- 设置侧边栏样式 -->
        <el-menu :default-active="$route.path" router class="el-menu-demo">
          <el-menu-item index="/home">
            <i class="el-icon-elem"></i>
            <span>首页</span>
          </el-menu-item>
          <el-menu-item index="/news">
            <i class="el-icon-elem"></i>
            <span>资讯</span>
          </el-menu-item>
<!--          <el-menu-item index="/chatGPT">-->
<!--            <i class="el-icon-elem"></i>-->
<!--            <span>创客AI</span>-->
<!--          </el-menu-item>-->
<!--          <el-submenu index="userList">-->
<!--            <template slot="title">-->
<!--              <i class="el-icon-warning"></i>-->
<!--              <span>学习资源</span>-->
<!--            </template>-->
<!--            <el-menu-item index="/">****</el-menu-item>-->
<!--            <el-menu-item index="/">****</el-menu-item>-->
<!--          </el-submenu>-->

          <el-submenu index="adminList">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>面试</span>
            </template>
            <el-menu-item index="/content">面试流程</el-menu-item>
            <el-menu-item index="/publish">面试结果</el-menu-item>
          </el-submenu>

          <el-submenu index="competition">
            <template slot="title">
              <i class="el-icon-warning"></i>
              <span>比赛信息</span>
            </template>
            <el-menu-item index="/competition">报名参赛</el-menu-item>
            <el-menu-item index="/competitionMessage">参赛信息</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!--主体数据-->
      <div style="flex: 1; background-color: white; margin-left: 200px; overflow-y: auto; overflow-x: hidden;"> <!-- 主体区域样式 -->
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: "UserLayout",
  data() {
    return {
      user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {}
    }
  },
  methods: {
    logout() {
      // 清除浏览器数据
      Cookies.remove('user');
      // 判断当前路由是否已经是登录页，避免重复导航
      if (this.$route.path !== '/login') {
        this.$router.push('/login');
      }
    }
  }
}
</script>

<style scoped>
/* 可在此处添加额外的CSS样式 */
</style>
