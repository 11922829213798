<template>
  <div id="app" :style="{ width: windowWidth + 'px', height: windowHeight + 'px' }">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: 1263,
      windowHeight: 650
    };
  },
  mounted() {
    this.scalePage();
    window.addEventListener('resize', this.scalePage);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.scalePage);
  },
  methods: {
    scalePage() {
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }
  }
}
</script>

<style>
#app {
  display: block;
  margin: 0;
}
body {
  display: block;
  margin: 0;
}
</style>
